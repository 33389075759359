import { render, staticRenderFns } from "./FormPrivilege.vue?vue&type=template&id=52182f28&scoped=true&"
import script from "./FormPrivilege.vue?vue&type=script&lang=js&"
export * from "./FormPrivilege.vue?vue&type=script&lang=js&"
import style0 from "./FormPrivilege.vue?vue&type=style&index=0&id=52182f28&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52182f28",
  null
  
)

export default component.exports