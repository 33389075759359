<template>
  <b-modal v-model="showModal" ref="modalSelectProduct" centered @hidden="hide">
    <template #modal-header>Choose a Privilege Item</template>
    <b-tabs v-model="tabSelected">
      <b-row>
        <b-col>
          <b-input-group class="my-2">
            <b-form-input
              type="search"
              :placeholder="
                tabSelected == 0 ? 'Search Coupon Name' : 'Search Stamp Name'
              "
              v-model="filter.search"
              @keyup.enter="searchCodeList"
   
            ></b-form-input>
            <b-input-group-append>
              <b-input-group-text>
                <b-iconstack
                  font-scale="2"
                  type="submit"
                  @click.prevent="searchCodeList"
                >
                  <b-icon
                    stacked
                    icon="search"
                    scale="0.5"
                    variant="grey"
                  ></b-icon>
                </b-iconstack>
              </b-input-group-text>
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>
      <b-tab title="Coupon Name">
        <b-table
          responsive
          striped
          hover
          :fields="fields"
          :items="CodeDataList"
          :busy="isBusy"
          show-empty
          empty-text="No matching records found"
        >
          <template v-slot:cell(isCheck)="data">
            <b-form-group>
              <b-form-checkbox-group v-model="couponSelected">
                <b-form-checkbox
                  :key="data.item.id"
                  :value="data.item.id"
                  @change="setNewCodeData(data.item)"
                  :disabled="
                    checkRedeem &&
                    freezeItemsSelected
                      .filter((el) => el.privilege_type_id == 1)
                      .filter((el) => el.reference_id == data.item.id).length >
                      0
                  "
                >
                </b-form-checkbox>
              </b-form-checkbox-group>
            </b-form-group>
          </template>
          <template v-slot:cell(image_url)="data">
            <div v-if="data.item.image_url">
              <b-img
                class="box-image1-1"
                :src="data.item.image_url"
                fluid
                @error="handleImageSrc"
              ></b-img>
            </div>
            <div v-else>-</div>
          </template>
          <template v-slot:cell(name)="data">
            <div class="text-left">{{ data.item.name }}</div>
            <div class="line-clamp2 description">{{ data.item.detail }}</div>
          </template>
          <template v-slot:cell(validFrom)="data">
            <div>{{ data.item.validFrom | moment($formatDateNew) }}</div>
            <div class="time-color">
              {{ data.item.validFrom | moment("HH:mm:ss") }}
            </div>
          </template>
          <template v-slot:cell(validTo)="data">
            <div>{{ data.item.validTo | moment($formatDateNew) }}</div>
            <div class="time-color">
              {{ data.item.validTo | moment("HH:mm:ss") }}
            </div>
          </template>
        </b-table>
        <b-row>
          <b-col
            cols="12"
            md="6"
            class="form-inline justify-content-center justify-content-sm-start"
          >
            <p class="mb-0 p-gray">
              Showing
              <span v-if="CodeDataList.length === 0" id="from-row">0</span>
              <span v-else>{{ showing }}</span> to
              <span>
                {{
                  filter.take * filter.page > rows
                    ? rows
                    : (filter.take * filter.page) | numeral("0,0")
                }}</span
              >
              of <span>{{ rows }}</span> entries
            </p>
            <!-- <p class="mb-0 p-gray">
                        Showing {{ showing }} to {{ showingTo }} of {{ rows }} entries
                      </p> -->
          </b-col>
          <b-col
            cols="12"
            md="6"
            class="form-inline justify-content-center justify-content-sm-end mt-2 mt-sm-0"
          >
            <b-pagination
              v-model="filter.page"
              :total-rows="rows"
              :per-page="filter.take"
              first-text="First"
              prev-text="Prev"
              next-text="Next"
              last-text="Last"
              class="m-md-0"
              @change="pagination"
            ></b-pagination>
          </b-col>
        </b-row>
      </b-tab>
      <b-tab title="Stampcard">
        <b-table
          responsive
          striped
          hover
          :fields="fieldsStampcard"
          :items="CodeDataList"
          :busy="isBusy"
          show-empty
          empty-text="No matching records found"
        >
          <template v-slot:cell(isCheck)="data">
            <b-form-checkbox-group v-model="StampCardSelected">
              <b-form-checkbox
                :key="data.item.id"
                :value="data.item.id"
                @change="setNewCodeData(data.item)"
                :disabled="
                  checkRedeem &&
                  freezeItemsSelected
                    .filter((el) => el.privilege_type_id == 2)
                    .filter((el) => el.reference_id == data.item.id).length > 0
                "
              >
              </b-form-checkbox>
            </b-form-checkbox-group>
          </template>
          <template v-slot:cell(name)="data">
            <div class="text-left">{{ data.item.name }}</div>
          </template>
          <template v-slot:cell(validFrom)="data">
            <div class="justify-content-center">
              <div>
                {{ $moment(data.item.validFrom).format("DD/MM/YYYY") }}
              </div>
              <div class="time">
                ({{ $moment(data.item.validFrom).format("HH:mm:ss") }})
              </div>
            </div>
          </template>
          <template v-slot:cell(validTo)="data">
            <div class="justify-content-center">
              <div>
                {{ $moment(data.item.validTo).format("DD/MM/YYYY") }}
              </div>
              <div class="time">
                ({{ $moment(data.item.validTo).format("HH:mm:ss") }})
              </div>
            </div>
          </template>
        </b-table>
        <b-row>
          <b-col cols="12" md="6" class="form-inline justify-content-start">
            <p class="mb-0 p-gray">
              Showing
              <span v-if="CodeDataList.length === 0" id="from-row">0</span>
              <span v-else>{{ showing }}</span> to
              <span>
                {{
                  filter.take * filter.page > rows
                    ? rows
                    : (filter.take * filter.page) | numeral("0,0")
                }}</span
              >
              of <span>{{ rows }}</span> entries
            </p>
          </b-col>
          <b-col cols="12" md="6" class="form-inline justify-content-end">
            <b-pagination
              v-model="filter.page"
              :total-rows="rows"
              :per-page="filter.take"
              first-text="First"
              prev-text="Prev"
              next-text="Next"
              last-text="Last"
              class="m-md-0"
              @change="pagination"
            ></b-pagination>
          </b-col>
        </b-row>
      </b-tab>
    </b-tabs>
    <template #modal-footer>
      <b-row>
        <b-col class="text-left">
          <b-button @click.prevent="hide" class="border-btn">Cancel</b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-button class="submit-btn" @click.prevent="submit">Save</b-button>
        </b-col>
      </b-row>
    </template>
  </b-modal>
</template>

<script>
export default {
  props: {
    form: {
      required: true,
      type: Object,
    },
    id: {
      required: true,
      type: String,
    },
    checkRedeem: {
      required: true,
      type: Boolean,
    },
    freezeItemsSelected: {
      required: false,
    },
  },
  data() {
    return {
      showModal: false,
      isBusy: false,
      filter: {
        id: null,
        search: "",
        page: 1,
        take: 5,
        reference_id: 0,
        show_only_select: 0,
        item_select: [],
      },
      CodeDataList: [],
      rows: 0,
      fields: [
        {
          key: "isCheck",
          label: "",
        },
        {
          key: "image_url",
          label: "Image",
          class: "w-200",
        },
        {
          key: "name",
          label: "Coupon Name",
        },
        {
          key: "validFrom",
          label: "Valid From",
        },
        {
          key: "validTo",
          label: "Valid To",
        },
      ],
      fieldsStampcard: [
        {
          key: "isCheck",
          label: "",
        },
        {
          key: "name",
          label: "Stampcard Name",
        },
        {
          key: "validFrom",
          label: "Valid From",
        },
        {
          key: "validTo",
          label: "Valid To",
        },
      ],
      tabSelected: 0,
      privilegeTypeID: 1,
      isDisabled: false,
      showing: 1,
      showingTo: 5,
      privilegeDetail: [],
      selected: [],
      couponSelected: [],
      StampCardSelected: [],
    };
  },
  created: async function () {
    this.filter.id = 1;
  },
  watch: {
    tabSelected(val) {
      this.filter.page = 1;
      if (val === 0) {
        this.filter.search = "";
        this.filter.id = 1;
        this.filter.item_select = this.privilegeDetail
          .filter((el) => el.privilege_type_id == 1)
          .map(({ reference_id }) => reference_id);
        this.getCodeDataList();
      } else {
        this.filter.search = "";
        this.filter.id = 2;
        this.filter.item_select = this.privilegeDetail
          .filter((el) => el.privilege_type_id == 2)
          .map(({ reference_id }) => reference_id);
        this.getCodeDataList();
      }
    },
    CodeDataList: function () {
      for (const code of this.CodeDataList) {
        if (code.isCheck === 1) {
          if (this.tabSelected == 0) this.couponSelected.push(code.id);
          else this.StampCardSelected.push(code.id);
        }
      }
    },
  },
  methods: {
    async show() {
      this.showModal = true;
      this.filter.item_select = [];
      this.privilegeDetail = [...this.form.privilegeCode.privilegeDetail];
      if (this.id !== "0") {
        this.filter.reference_id = parseInt(this.reference_id);
      }

      this.filter.item_select = this.privilegeDetail
        .filter((el) => el.privilege_type_id == 1)
        .map(({ reference_id }) => reference_id);
      this.StampCardSelected = [];
      this.couponSelected = [];
      this.selected = [];
      this.tabSelected = 0;
      await this.getCodeDataList();
    },
    hide() {
      this.showModal = false;
    },
    async getCodeDataList() {
      this.isBusy = true;
      await this.$store.dispatch("ActionGetPrivilegeCondition", this.filter);
      var data = await this.$store.state.modulePrivilegeCode
        .stateGetPrivilegeCondition;
      if (data.result == 1) {
        this.CodeDataList = data.detail.detail;
        this.rows = data.detail.total_count;
        this.isBusy = false;
      }
    },
    setNewCodeData(data) {
      let body = {
        id: 0,
        name: data.name ? data.name : "",
        image_url: data.image_url ? data.image_url : "",
        privilege_type_id: this.filter.id,
        reference_id: data.id,
        prefix: data.prefix ? data.prefix : "",
        valid_from: data.validFrom,
        valid_to: data.validTo,
        sort_order: this.privilegeDetail.length + 1,
        description: data.description,
        short_description: data.short_description,
        coupon_id: data.coupon_id,
      };
      if (this.privilegeDetail.length > 0) {
        let filterPrivilegeOut = this.filter.item_select.includes(data.id);
        if (!filterPrivilegeOut) {
          this.filter.item_select.push(data.id);
          this.privilegeDetail.push(body);
        } else {
          let positionArry = this.privilegeDetail.findIndex(
            (el) =>
              el.reference_id === data.id &&
              body.privilege_type_id == el.privilege_type_id
          );

          this.privilegeDetail.splice(positionArry, 1);

          let positionFilterItem = this.filter.item_select.indexOf(data.id);
          this.filter.item_select.splice(positionFilterItem, 1);
        }
      } else {
        this.filter.item_select.push(data.id);
        this.privilegeDetail.push(body);
      }
    },
    submit() {
      this.$emit("submitSelect", this.privilegeDetail);
      this.hide();
    },
    pagination(value) {
      this.filter.page = value;
      if (value === 1) {
        this.showing = this.filter.page;
        this.showingTo = this.filter.take;
      } else {
        this.showing = value * this.filter.take - this.filter.take;
        this.showingTo = this.showing + this.filter.take;
      }
      this.getCodeDataList();
    },
    searchCodeList() {
      this.getCodeDataList();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .nav-tabs .nav-link:not(.active):hover {
  background-color: transparent;
  color: var(--primary-color);
  border-bottom: 4px solid var(--primary-color);
  border-top-color: transparent;
  border-right-color: transparent;
  border-left-color: transparent;
}
::v-deep .nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  background-color: transparent !important;
  color: var(--primary-color) !important;
  border-bottom: 4px solid var(--primary-color) !important;
}
::v-deep .modal-header {
  background-color: var(--secondary-color);
  color: #000;
  font-weight: 600;
}
::v-deep .modal-dialog {
  max-width: 800px;
}
::v-deep thead {
  background-color: var(--secondary-color);
  color: var(--font-color);
  text-align: center;
}
.time {
  color: #afafaf;
}
::v-deep .input-group-text {
  padding: 0;
  background-color: transparent;
}
.btn-save {
  color: #fff;
  background-color: var(--primary-color);
  width: 100%;
  border: none;
  padding: 5px 50px;
  border-radius: 0px;
}
::v-deep .modal-footer {
  justify-content: space-between;
}
.image {
  width: 50%;
  height: 50%;
  padding-top: 42.9%;
  margin-left: 25%;
  background-position: center !important;
  background-size: cover;
  background-repeat: no-repeat;
}
.line-clamp2 {
  word-break: break-word;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}
</style>
